import { render, staticRenderFns } from "./DashboardUser.vue?vue&type=template&id=c4bc2690&scoped=true&"
import script from "./DashboardUser.vue?vue&type=script&lang=js&"
export * from "./DashboardUser.vue?vue&type=script&lang=js&"
import style0 from "./DashboardUser.vue?vue&type=style&index=0&id=c4bc2690&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4bc2690",
  null
  
)

export default component.exports