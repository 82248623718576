<template>
  <div class="inner-section">
    <ValidationObserver>
      <card>
        <template v-slot:searchHeaderTitle>
          <h4 class="card-title">{{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:searchBody>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
                <ValidationProvider name="Organization" vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                    <template v-slot:label>
                        {{$t('organogram.org')}} <span class="text-danger">*</span>
                    </template>
                    <b-form-select
                        plain
                        v-model="search.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                    >
                        <template v-slot:first>
                        <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                    </b-form-select>
                    <div class="invalid-feedback">
                        {{ errors[0] }}
                    </div>
                    </b-form-group>
                </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
              <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                  <b-form-group
                      label-for="office_type_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('organogram.office_type')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.office_type_id"
                      :options="officeTypeList"
                      id="office_type_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
              <ValidationProvider name="Office name" vid="office_id" rules="required|min_value:1">
                  <b-form-group
                      label-for="office_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('organogram.office_name')}} <span class="text-danger">*</span>
                  </template>
                  <b-form-select
                      plain
                      v-model="search.office_id"
                      :options="officeList"
                      id="office_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
              <ValidationProvider name="Designation" vid="designation_id">
                  <b-form-group
                      label-for="designation_id"
                      slot-scope="{ valid, errors }"
                  >
                  <template v-slot:label>
                      {{$t('organogram.designation')}}
                  </template>
                  <b-form-select
                      plain
                      v-model="search.designation_id"
                      :options="designationList"
                      id="designation_id"
                      :state="errors[0] ? false : (valid ? true : null)"
                  >
                      <template v-slot:first>
                      <b-form-select-option :value=0>{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                  </b-form-select>
                  <div class="invalid-feedback">
                      {{ errors[0] }}
                  </div>
                  </b-form-group>
              </ValidationProvider>
            </b-col>
          </b-row>
        </template>
      </card>
      <body-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('userManagement.dashboard_user') }} {{ $t('globalTrans.list') }}</h4>
        </template>
        <template v-slot:body>
          <b-overlay :show="loadTable">
            <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
              <b-form  id="form" @submit.prevent="handleSubmit(userSave)" @reset.prevent="reset">
                <b-row>
                  <b-col lg="12" sm="12" class="user-section mt-3">
                    <table class="table" style="width:100%">
                      <thead class="thead">
                        <tr>
                          <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                          <th>{{$t('globalTrans.name')}}</th>
                          <th>{{$t('globalTrans.mobile')}}</th>
                          <th>{{$t('globalTrans.designation')}}</th>
                          <th>{{$t('globalTrans.select')}}</th>
                        </tr>
                      </thead>
                      <tr v-for="(detail,index) in search.details" :key="index">
                        <td class="text-center">{{$n(index+1)}}</td>
                        <td>
                          {{ ($i18n.locale==='bn') ? detail.name_bn : detail.name }}
                        </td>
                        <td>
                          {{ detail.mobile }}
                        </td>
                        <td>
                          {{ ($i18n.locale==='bn') ? detail.designation_name_bn : detail.designation_name }}
                        </td>
                        <td>
                          <b-form-checkbox
                            :id="'checkbox-' + detail.id"
                            v-model="detail.dashboard_user"
                            :name="'checkbox-' + detail.id"
                            value=1
                            unchecked-value=0
                          >
                          </b-form-checkbox>
                        </td>
                        <td>
                        </td>
                      </tr>
                      <template v-if="search.details.length === 0">
                        <tr>
                          <th colspan="5" class="text-center text-danger">{{$t('globalTrans.noDataFound')}}</th>
                        </tr>
                      </template>
                    </table>
                  </b-col>
                </b-row>
                <div class="row" v-if="search.details.length > 0">
                  <div class="col-sm-3"></div>
                  <div class="col text-right">
                    <b-button type="submit" variant="primary" class="mr-2">{{ $t('globalTrans.save') }}</b-button>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </b-overlay>
        </template>
      </body-card>
    </ValidationObserver>
  </div>
</template>
<script>
import RestApi, { authServiceBaseUrl } from '@/config/api_config'
import { userListByDesignWise } from '../api/routes'
import { mapGetters } from 'vuex'

export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      loadTable: false,
      valid: null,
      errors: [],
      search: {
        org_id: 0,
        office_type_id: 0,
        office_id: 0,
        designation_id: 0,
        details: []
      },
      officeTypeList: [],
      officeList: [],
      designationList: [],
      loader: false
    }
  },
  created () {
  },
  computed: {
    orgList () {
      return this.$store.state.CommonService.commonObj.orgProfileList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    },
    currentLocale () {
      return this.$i18n.locale
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeTypeList = this.getOfficeTypeList(newVal)
        this.setDesignationList(newVal)
      }
    },
    'search.office_type_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.officeList = this.getOfficeList(newVal)
      }
    },
    'search.office_id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.userData()
      }
    },
    'search.designation_id': function (newVal, oldVal) {
      this.userData()
    }
  },
  methods: {
    async userData () {
      this.loadTable = true
      const search = this.search
      search.details = []
      await RestApi.getData(authServiceBaseUrl, userListByDesignWise, search).then(response => {
        if (response.success) {
          const listData = response.data.map(item => {
            const designationObj = this.$store.state.CommonService.commonObj.designationList.find(doc => doc.value === parseInt(item.designation_id))
            const designationData = {}
            if (typeof designationObj !== 'undefined') {
              designationData.designation_name = designationObj.text_en
              designationData.designation_name_bn = designationObj.text_bn
            } else {
              designationData.designation_name = ''
              designationData.designation_name_bn = ''
            }
            return Object.assign({}, item, designationData)
          })
          this.search.details = listData
          this.loadTable = false
        }
      }, error => {
        this.loadTable = false
        if (error) {
         //
        }
      })
    },
    async userSave () {
      this.loadTable = true
      const search = this.search
      await RestApi.postData(authServiceBaseUrl, 'user-management/user/dashboard-user', search).then(response => {
        // this.userData()
         this.$toast.success({
          title: 'Success',
          message: 'Data saved successfully',
          color: '#D6E09B'
        })
        this.loadTable = false
      }, error => {
        if (error) {
          this.$toast.error({
            title: 'Error',
            message: 'Operation failed! Please, try again.'
          })
        }
        this.loadTable = false
      })
    },
    setOfficeList (orgId, officeTypeId) {
      this.search.office_id = 0
      let officeList = []
      if (officeTypeId) {
         officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === officeTypeId)
         this.officeList = officeList
         return
      }
      officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.org_id === orgId)
      this.officeList = officeList
    },
    setDesignationList (orgId) {
      const desigList = this.$store.state.CommonService.commonObj.designationList.filter(item => item.status === 1 && item.org_id === orgId)
      this.designationList = desigList
    },
    getOfficeTypeList (id) {
      return this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 1 && item.org_id === id)
    },
    getOfficeList (id) {
      return this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 1 && item.office_type_id === id)
    }
  }
}
</script>
<style scoped>
  .user-section .iq-card {
    border: solid 1px #e6e6e6
  }
  .role-section .iq-card {
    border: solid 1px #e6e6e6
  }
</style>
